<template>
  <div class="message">
    <div class="message-box">
      <div class="message-list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="m-item"
            @click="linkDetail(item)"
            v-for="item in list"
            :key="item.id"
          >
            <div class="title" :class="item.readFlag == '0' ? 'active' : ''">
              <span>{{
                item.readFlag == "0"
                  ? "未读"
                  : item.readFlag == "1"
                  ? "已读"
                  : item.readFlag
              }}</span>
              {{ item.titile }}
            </div>
            <div class="time">{{ item.sendTime }}</div>
          </div>
        </van-list>
      </div>
    </div>

    <TabberItem :active="active"></TabberItem>
  </div>
</template>

<script>
import { Toast, List } from "vant";
import TabberItem from "@/components/TabberItem";
import { initMixin } from "@/mixins/initMixin";
export default {
  name: "index",
  mixins: [initMixin],
  data() {
    return {
      loading: false,
      finished: false,
      current: 0,
      size: 10,

      active: 3,

      list: [],
    };
  },
  methods: {
    // 下拉加载列表
    onLoad() {
      setTimeout(() => {
        this.getDate();
      }, 1000);
    },

    // 获取我的会议
    async getDate() {
      this.current++;
      let params = {
        pageNo: this.current,
        pageSize: this.size,
      };
      await this.$get(
        `/sys/sysAnnouncementSend/getMyAnnouncementSend`,
        params
      ).then((res) => {
        if (res.code == "0") {
          if (this.list.length == 0) {
            this.list = res.result.records;
          } else {
            this.list = [...this.list, ...res.result.records];
          }

          // 加载状态结束
          this.loading = false;

          // 数据全部加载完成
          if (res.result.records.length < this.size) {
            this.finished = true;
          }
        }
      });
    },
    // 跳转详情
    async linkDetail(item) {
      let params = {
        anntId: item.anntId,
      };
      await this.$put(
        `/sys/sysAnnouncementSend/editByAnntIdAndUserId`,
        params
      ).then((res) => {
        if (res.code == "0") {
          return this.$router.push({
            path: "/messagedetail",
            query: { id: item.id },
          });
        }
      });
    },
  },
  created() {
    this.initFn();
  },
  mounted() {},
  components: {
    [Toast.name]: Toast,
    [List.name]: List,
    TabberItem,
  },
};
</script>

<style lang="scss" scoped>
.message {
  background: #f4f4f4;
  min-height: 100vh;
}
.message-box {
  overflow: hidden;
}
.message-list {
  background: #fff;
  padding: 3px 13px;
  margin: 12px;
  box-shadow: 0px 11px 16px 0px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  font-size: 14px;
  .m-item {
    border-bottom: 1px solid #dedede;
    padding: 10px 0;
  }
  .m-item:last-child {
    border-bottom: none;
  }
  .title {
    color: #959595;
    span {
      // width: 32px;
      // height: 16px;
      // text-align: center;
      // line-height: 16px;
      // display: inline-block;
      background: #c1c1c1;
      border-radius: 8px;
      font-size: 10px;
      color: #fefefe;
      padding: 2px 4px;
    }
    &.active {
      color: #4e65db;
      span {
        background: #e54545;
      }
    }
  }
  .time {
    color: #959595;
    margin-top: 10px;
  }
}
</style>