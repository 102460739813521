<template>
  <div class="message">
    <div class="message-box">
        <div class="message-list">
            <div class="m-item">
                <div class="title">{{detail.msgContent}}</div>
                <div class="time">{{detail.sendTime}}</div>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
import { button, Toast, NavBar, Cell, CellGroup, List ,Tabbar, TabbarItem} from "vant";
import Cookies from "js-cookie";
import config from "@/config";
import TabberItem from "@/components/TabberItem";
export default {
  name: "index",
  data() {
    return {
      loading: false,
      finished: false,
      id:this.$route.query.id,
      current: 0,
      size: 10,

      active: 3,

      list: [],
      detail:{}
    };
  },
  methods: {
    // 会议详情
    async getOne() {
      let params = {
        sendId: this.id,
      }
      await this.$get(`/sys/sysAnnouncementSend/getOne`, params).then(
        (res) => {
          if (res.code == "200") {
            this.detail = res.result
          }
        }
      );
    },

  },
  created() {
    this.getOne();
  },
  mounted() {},
  components: {
    [Toast.name]: Toast,
    [button.name]: button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    TabberItem
  },
};
</script>

<style lang="scss" scoped>

.message {
  background: #f4f4f4;
  min-height: 100vh;
}
.message-box{
    overflow: hidden;
}
.message-list {
    background: #fff;
    padding: 3px 13px;
    margin: 12px;
    box-shadow: 0px 11px 16px 0px rgba(0,0,0,0.02);
    border-radius: 10px;
    font-size: 14px;
    .m-item{
        padding: 20px 0;
    }

    .title{
        color: #4D4D4D;
    }
    .time{
        color: #959595;
        margin-top: 20px;
    }
}

</style>